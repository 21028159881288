import { Component, OnInit, Input, OnDestroy, Output, HostListener } from '@angular/core';

import { FormProcessorService } from '@app/-core/services/form-processor.service';

import { PopoverController, AnimationController, ModalController } from '@ionic/angular';

import { PopoverProfileComponent, ModalNotificationsComponent, PopoverSitesComponent, CrmPopoverComponent } from '@app/-core/components-v2/navigation-toolbar/toolbar-shared-actions.component';

import { environment } from '@env/environment';
import { AuthService } from '@app/-core/services/auth.service';

import { Storage } from '@ionic/storage';

import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'gw-navigation-toolbar',
  templateUrl: './navigation-toolbar.component.html',
  styleUrls: ['./navigation-toolbar.component.scss'],
})
export class NavigationToolbarComponent implements OnInit {

  // @Input() inset = false; // Defines if the toolbar is inset of complex page or full featured full width toolbar

  public menuItems = [

  ];

  env = environment;

  public isMenuOpen: boolean = false;
  public isCrmPopoverOpen: boolean = false;

  sidemenuEnterAnimation = (baseEl: any) => {
    const backdropAnimation = this.animationCtrl.create()
      .addElement(baseEl.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.00', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl.create()
      // @ts-ignore
      .addElement(baseEl.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(1) translateX(calc(100vw + 0px))' },
        { offset: 1, opacity: '1', transform: 'scale(1) translateX(calc(100vw - 400px))' }
      ]);

    return this.animationCtrl.create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(300)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  sidemenuLeaveAnimation = (baseEl: any) => {
    return this.sidemenuEnterAnimation(baseEl).direction('reverse');
  };

  constructor(
    public formProcessor: FormProcessorService,
    public popoverController: PopoverController,
    public modalController: ModalController,
    protected animationCtrl: AnimationController,
    public storage: Storage,

    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  @Input() presentUpgradeBanner = false;
  
  getTriggerTarget(link) {
    // if link contains '#upgrade-banner' return '_self' otherwise '_blank'
    return (link.includes('#upgrade-banner')) ? '_self' : '_blank';
  }

  triggerBannerAction(source, trigger) {
    console.log('triggerBannerAction', source, trigger);
    // WAS - this.auth.authAppendPathtroughParams(this.auth.data.upgrade_unlimited_link)
    // this.formProcessor.presentAccountUpgradeModal('premium-template')

    const prefix = (source === 'banner') ? 'center_' : 'button_';

    const check = prefix + 'triggers';

    if (!this.formProcessor.isMobileView) {

      //* Desktop version - return popup or link based on trigger
      if ( this.auth.data.banner_data[check] === 'popup' ) {
        if ( trigger === 'link' ) {
          if ( window.location.href.includes('#upgrade-banner') ) {
            return window.location.href;
          } else {
            return window.location.href + '#upgrade-banner';
          }
        } else {
          this.formProcessor.presentAccountUpgradeModal(this.auth.data.banner_data[prefix + 'action']);
        }
      }
  
      if ( this.auth.data.banner_data[check] === 'link' ) {
        if ( trigger === 'link' ) {
          return this.auth.authAppendPathtroughParams(this.auth.data.banner_data[prefix + 'action']);
        } else {
          return null;
        }
      }

    } else {
      
       //* Mobile version - always return direct payment link
      if ( this.auth.data.banner_data[check] === 'popup' ) {
        if ( trigger === 'link' ) {
          return this.auth.authAppendPathtroughParams(this.auth.data.banner_data.popup_action_link);
        } else {
          return null;
        }
      }
      if ( this.auth.data.banner_data[check] === 'link' ) {
        if ( trigger === 'link' ) {
          return this.auth.authAppendPathtroughParams(this.auth.data.banner_data[prefix + 'action']);
        } else {
          return null;
        }
      }

    }

  }

  dismissUpgradeBanner() {
    // dismiss for 24h +
    this.storage.set('dismissUpgradeBanner', new Date().getTime() + 24 * 60 * 60 * 1000);
    this.presentUpgradeBanner = false;
  }

  ngOnInit() {

    console.log('public formProcessor: FormProcessorService', this.formProcessor);

    const evaluateMenuItemsExpressions = () => {

      //check if show upgrade banner
      // const dissmisTs = await this.storage.get('dismissUpgradeBanner');
      this.storage.get('dismissUpgradeBanner').then( (res) => {
        if ( res ) {
          // verify if less then 24 or esists at all
          if ( new Date().getTime() > res ) {
            this.presentUpgradeBanner = true;
            if ( this.auth && this.auth.data && (!this.auth.data.banner_data.center_title) ) {
              this.presentUpgradeBanner = false;
            }
          }
        } else {
          if ( this.auth.data.banner_data.center_title ) {
            this.presentUpgradeBanner = true;
          } else {
            this.presentUpgradeBanner = false;
          }
          
        }
      });

      this.menuItems = [
        {
          title: 'Campaigns',
          url: '/app/dashboard',
          icon: null,
          expression: () => true,
          pathIncludes: [
            '/app/dashboard',
            '/app/planner',
            '/app/roadmap',

            '/app/assets',
            '/app/funnels',
          ]
        },

        {
          title: 'Agency',
          url: '/app/agency',
          icon: null,
          expression: () => {
            return (this.formProcessor.appFeature('AgencyWebsites') && this.auth.data.is_coach) || this.auth.data.is_staff;
          },
          // badge: 'new',
        },
        {
          title: 'Websites',
          url: '/app/websites',
          icon: null,
          expression: () => {
            return this.formProcessor.appFeature('AgencyWebsites') && this.auth.data.has_websites;
          },
          // badge: 'new',
        },

        {
          title: 'Brand Studio',
          url: '/app/brand-center',
          icon: null,
          expression: () => false
        },

        {
          title: 'CRM',
          // url: '/app/crm',
          icon: null,
          expression: () => {
            return this.auth.data.is_superstaff ||  this.auth.data.has_funnels;
          },
          badge: 'new',
          crmDropDown: true,
        },

        {
          title: 'Products',
          url: '/app/products',
          icon: null,
          expression: () => {
            return this.auth.data.is_superstaff ||  this.auth.data.has_funnels;
          },
          badge: 'new',
        },

        {
          title: 'Inbox',
          url: '/app/inbox',
          icon: null,
          expression: () => {
            return this.auth.data.is_superstaff
          },
          badge: 'new',
        },


      ];
    };

    evaluateMenuItemsExpressions();

    this.auth.getTokenChange().subscribe(value => {
      evaluateMenuItemsExpressions();
    });

    
    setTimeout(() => {
      // this.auth.data.is_staff = true;
      evaluateMenuItemsExpressions();
    }, 2000);
    
    

  }

  async presentProfilePopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverProfileComponent,
      cssClass: 'application-v2 popover-v2 profile-popover profile-popover-mobile',
      event: ev,
      showBackdrop: false,
    });
    await popover.present();
  }

  async presentNotifications(ev: any) {

    const modal = await this.modalController.create({
      component: ModalNotificationsComponent,
      cssClass: 'application-v2 modal-v2 notifications-modal',
      // event: ev,
      showBackdrop: false,
      enterAnimation: this.sidemenuEnterAnimation,
      leaveAnimation: this.sidemenuLeaveAnimation,
    });
    await modal.present();
  }

  async presentSitesPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverSitesComponent,
      cssClass: 'application-v2 popover-v2 sites-popover',
      event: ev,
      showBackdrop: false,
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async presentCrmPopover(ev: any) {

    this.isCrmPopoverOpen = true;
    const popover = await this.popoverController.create({
      component: CrmPopoverComponent,
      cssClass: 'application-v2 popover-v2 crm-popover',
      event: ev,
      showBackdrop: false,
    });
    await popover.present();

    // Reset the state (arrow up/down) when popover is dismissed
    const { role } = await popover.onDidDismiss();
    this.isCrmPopoverOpen = false;

  }

  isCrmPathActive(): boolean {
    const crmPaths = ['/app/crm', '/app/crm/pipelines', '/app/crm/tags'];
    return crmPaths.some(path => window.location.pathname.startsWith(path));
  }

  mathesPathInclusions(item) {
    // if ( item.pathIncludes ) {
    //   // based on wildcard item.pathIncludes contains window.location.pathname
      
    //   return item.pathIncludes.some( (path) => { return window.location.pathname.includes(path); } );
    //  }

    if (item.title === 'CRM') {
      return this.isCrmPathActive();
    }
    if (item.pathIncludes) {
      // based on wildcard item.pathIncludes contains window.location.pathname
      return item.pathIncludes.some((path) => window.location.pathname.includes(path));
    }
    return false;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.isMenuOpen) {
      const menuElement = event.target as HTMLElement;
      if (!menuElement.closest('.menu-open') && 
          !menuElement.closest('.menu-toggle')) {
        this.closeMenu();
      }
    }
  }

  navigateTo(path) {
    this.router.navigate([path], { replaceUrl: true });
    this.closeMenu();
  }
}
